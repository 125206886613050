import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from './shared/api/auth.service';
import { map, takeUntil, timer } from 'rxjs';
import { ShopService } from './shared/api/shop.service';
import { DestroyableComponent } from './shared/mixins/destroy.mixin';

@Component({
  selector: 'bb-root',
  templateUrl: `./app.component.html`,
  styleUrls: ['./app.component.css'],
})
export class AppComponent extends DestroyableComponent implements OnInit {
  private _authService = inject(AuthService);
  private _shopService = inject(ShopService);
  isLoading = true;

  isTimeElapsedForLoader = timer(2000).pipe(map(() => true));

  ngOnInit(): void {
    this.getShopList();
    this.getUserProfile();
  }

  getShopList() {
    this._shopService
      .getShopList()
      .pipe(takeUntil(this._destroy$))
      .subscribe((shopList) => {
        this._shopService.setShopList(shopList);
      });
  }

  getUserProfile() {
    // get the auth information from storage
    this._authService.setAuthInfoFromStorage();

    const authInfo = this._authService.getAuthInfo().value;
    if (!authInfo?.userId) {
      this.isLoading = false;
      return;
    }

    // when auth information is available, load the user profile
    this._authService.getProfile(authInfo.userId).subscribe((userProfile) => {
      this._authService.setUserProfile(userProfile);
      this.isLoading = false;
    });
  }
}
