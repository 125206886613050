import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RestApiCall {
  protected _http = inject(HttpClient);
  baseUrl = environment.apiBaseUrl;

  prepareUrl(apiEndpoint: string) {
    return `${this.baseUrl}${apiEndpoint}`;
  }

  buildQueryParams(query: Object): HttpParams {
    let queryParams = new HttpParams();

    Object.entries(query).forEach(([key, value]) => {
      queryParams = queryParams.append(key, String(value));
    });

    return queryParams;
  }

  buildMultipartFormData(data: object) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: [string, string]) => {
      formData.append(key, value);
    });
    return formData;
  }
}
