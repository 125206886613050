import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

type Constructor<T = {}> = new (...args: any[]) => T;

type Destroyable = Constructor<OnDestroy>;

export function DestroyMixin<TBase extends Destroyable>(Base: TBase) {
  return class extends Base {
    _destroy$ = new Subject();

    override ngOnDestroy(): void {
      super.ngOnDestroy();
      this._destroy$.next(true);
      this._destroy$.complete();
    }
  };
}

export const DestroyableComponent = DestroyMixin(
  class implements OnDestroy {
    ngOnDestroy(): void {
      // provide generic logic for component destroy inside this method.
    }
  }
);
