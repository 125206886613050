import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./shops/shops.module').then((module) => module.ShopsModule),
  },
  {
    path: 'shop/:shopId',
    loadChildren: () =>
      import('./shop/shop.module').then((module) => module.ShopModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'shop/details/not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
